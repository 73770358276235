import { Capacitor } from '@capacitor/core';
import { BrowserMultiFormatReader } from '@zxing/browser';
import {
  BarcodeScanner,
  BarcodeFormat,
  LensFacing,
} from '@capacitor-mlkit/barcode-scanning';

let codeReader = null;

window.scanCode = async function () {
    const isNative = Capacitor.isNativePlatform();

    if (isNative) {
        try {
            document.querySelector('body')?.classList.add('barcode-scanner-active');

            const listener = await BarcodeScanner.addListener('barcodeScanned', async result => {
                await listener.remove();
                document.querySelector('body')?.classList.remove('barcode-scanner-active');
                await BarcodeScanner.stopScan();

                if (result?.barcode?.displayValue) {
                    sendToBackend(result.barcode.displayValue);
                } else {
                    alert("No se detectó ningún código.");
                }
            });

            await BarcodeScanner.startScan();
        } catch (err) {
            console.error("Error escaneando en app:", err);
            alert("Error escaneando código.");
            document.querySelector('body')?.classList.remove('barcode-scanner-active');
            await BarcodeScanner.stopScan();
        }

    } else {
        // Escaneo en navegador
        const videoElement = document.getElementById('video');
        codeReader = new BrowserMultiFormatReader();
        videoElement.style.display = 'block';

        const buttonElement = document.getElementById('close-scanner-web');
        buttonElement.style.display = 'block';

        let hasScanned = false;

        try {
            await new Promise((resolve, reject) => {
                codeReader.decodeFromVideoDevice(null, 'video', (result, err) => {
                    if (hasScanned) return;

                    if (result) {
                        hasScanned = true;
                        videoElement.style.display = 'none';
                        buttonElement.style.display = 'none';
                        sendToBackend(result.text);
                        resolve();
                    }

                    if (err) {
                        reject(err);
                    }
                });
            });

            codeReader.reset();
        } catch (err) {
            console.error('Error accediendo a la cámara:', err);
            // alert('No se pudo acceder a la cámara');
            codeReader.reset();
            videoElement.style.display = 'none';
            buttonElement.style.display = 'none';
        }
    }
};

// Llamada al pulsar el botón de cerrar nativo
window.stopScan = async function () {
    const isNative = Capacitor.isNativePlatform();
    if (isNative) {
        try {
            await BarcodeScanner.stopScan();
        } catch (err) {
            console.error("Error al cerrar escáner:", err);
        }
        document.querySelector('body')?.classList.remove('barcode-scanner-active');
    } else {
        const videoElement = document.getElementById('video');
        const buttonElement = document.getElementById('close-scanner-web');

        // Detener el escáner web si está activo
        if (codeReader) {
            try {
                codeReader.reset(); // Detiene la cámara
            } catch (err) {
                console.warn("Error al resetear codeReader:", err);
            }
        }

        videoElement.style.display = 'none';
        buttonElement.style.display = 'none';
    }
};

function sendToBackend(code) {
    fetch("/scan-product", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')?.content
        },
        body: JSON.stringify({ ean: code })
    })
    .then(res => res.json())
    .then(data => {
        console.log("response... ", data);
        if (data.status === 1 && data.url) {
            window.location.href = data.url;
        } else if (data.status != 1) {
            const message = data.error_message || "Ha ocurrido un error.";
            alert(message);
        } else {
            alert("Error desconocido.");
        }
    })
    .catch(err => {
        console.error("Error al enviar:", err);
        alert("Error de conexión con el servidor.");
    });
}
